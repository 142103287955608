<!-- eslint-disable vue/no-v-html -->
<template>
    <div
        id="header-taglines"
        class="flex flex-wrap items-start justify-center text-sm text-center uppercase sm:w-4/5 mx-2.5 md:mx-12 my-5">
        <NuxtLink
            v-for="link in links"
            :id="link.id"
            :key="link.to"
            :to="link.to"
            :external="true"
            class="flex flex-col p-2 items-center justify-start h-full w-1/2 sm:w-1/3 md:w-1/5 hover:no-underline">
            <div class="mb-2.5 md:mb-5" v-html="link.svg"></div>
            <div class="text-[#b7deff] max-w-[160px]">{{ link.label }}</div>
        </NuxtLink>
    </div>
</template>
<script setup>
import whyAuthorizedSvg from "~/assets/tag-lines/why_authorized.svg?raw";
import buyersSvg from "~/assets/tag-lines/buyers.svg?raw";
import engineersSvg from "~/assets/tag-lines/engineers.svg?raw";
import distributorsSvg from "~/assets/tag-lines/distributors.svg?raw";
import factorySvg from "~/assets/tag-lines/factory.svg?raw";
const { t } = useI18n();
const localePath = useLangPath();

const links = [
    {
        to: localePath("/why-buy-authorized-electronic-components"),
        svg: whyAuthorizedSvg,
        label: t("Home.WhyAuthorizedMatters"),
        id: "article-link-why-buy-authorized",
    },
    { to: localePath("/features-buyers"), svg: buyersSvg, label: t("Home.Buyers"), id: "article-link-features-buyers" },
    {
        to: localePath("/features-engineers"),
        svg: engineersSvg,
        label: t("Home.Engineers"),
        id: "article-link-features-engineers",
    },
    {
        to: localePath("/features-distributors"),
        svg: distributorsSvg,
        label: t("Global.Distributors"),
        id: "article-link-features-distributors",
    },
    {
        to: localePath("/features-component-manufacturers"),
        svg: factorySvg,
        label: t("Home.ComponentManufacturers"),
        id: "article-link-features-component-manufacturers",
    },
];
</script>
