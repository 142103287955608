<template>
    <section class="container">
        <h2 class="mb-4">{{ $t("ProductSpotlight.ProductSpotlightTitle") }}</h2>
        <div ref="container" class="relative">
            <div ref="target" class="flex gap-4 overflow-x-auto snap-x pb-4">
                <div
                    v-for="product in products"
                    :key="product.Id"
                    class="snap-start border px-4 py-8 basis-60 shrink-0 flex flex-col gap-4">
                    <img :src="product.ImageUrl" alt="" class="block mx-auto" width="100" height="100" />
                    <div>
                        <NuxtLink :to="productLink(product)">{{ product.Name }}</NuxtLink>
                    </div>
                    <div class="text-sm">{{ product.Description }}</div>
                </div>
            </div>
            <UButton
                v-show="!arrivedState.left"
                class="absolute -left-8 top-1/2 -mt-4"
                icon="i-heroicons-chevron-left"
                variant="ghost"
                @click="scrollX -= 256" />
            <UButton
                v-show="!arrivedState.right && showScroll"
                class="absolute -right-8 top-1/2 -mt-4"
                icon="i-heroicons-chevron-right"
                variant="ghost"
                @click="scrollX += 256" />
        </div>
    </section>
</template>
<script setup>
import { useScroll, useResizeObserver } from "@vueuse/core";
const localePath = useLangPath();
const container = ref(null);
const target = ref(null);
const showScroll = ref(false);
const { data: products } = await useFetchLocaleApi("api/home/product-spotlights");
const { x: scrollX, arrivedState } = useScroll(target, { behavior: "smooth" });

useResizeObserver(container, ([entry]) => {
    const { width } = entry.contentRect;
    showScroll.value = width < target.value.scrollWidth;
});

function productLink(product) {
    const segment = encodeURI(product.Name.replace(/\W/g, "-"));
    return localePath(`/featured-products/${segment}`) + `?spotlightId=${product.Id}`;
}
</script>
