<template>
    <div>
        <HeaderHome />
        <div class="container-xl flex flex-col gap-4 my-8">
            <HomeSponsors />
            <HomeCategories />
            <HomeNewProducts v-if="globalConfig.Features.PRODUCT_SPOTLIGHT_ENABLED" />
        </div>
        <HomeBlog />
    </div>
</template>
<script setup>
useApm("/");
const { t } = useI18n();

definePageMeta({
    layout: "headless",
});

useSeoMeta({
    title: t("Global.TitleDefault"),
    ogTitle: t("Global.TitleDefault"),
    description: t("Home.Page_MetaDescription"),
    ogDescription: t("Home.Page_MetaDescription"),
});

const globalConfig = useStateGlobalConfig();
</script>
