<template>
    <div class="bg-gray-50 py-8">
        <section class="container-xl">
            <h2 class="text-3xl font-medium mb-4">{{ $t("Home.FeaturedPost") }}</h2>
            <div class="flex flex-col md:flex-row justify-between gap-8">
                <div class="w-full md:w-1/2">
                    <a
                        :href="useBlogUrl(localeFeaturedBlog.path)"
                        class="w-full"
                        @click="logBlogPostClick(localeFeaturedBlog.path)">
                        <img
                            :src="postImage"
                            :alt="localeFeaturedBlog.title"
                            class="w-full h-auto aspect-video object-contain" />
                    </a>
                </div>
                <div class="w-full md:w-1/2">
                    <h3 class="text-3xl font-medium">
                        <a
                            :href="useBlogUrl(localeFeaturedBlog.path)"
                            @click="logBlogPostClick(localeFeaturedBlog.path)"
                            >{{ localeFeaturedBlog.title }}</a
                        >
                    </h3>
                    <p class="my-4">{{ localeFeaturedBlog.description }}</p>
                    <div v-if="localeFeaturedBlog.relatedPosts.length" id="related-posts">
                        <b class="pb-2">{{ $t("Home.RelatedArticles") }}</b>
                        <div>
                            <ul>
                                <li v-for="post in localeFeaturedBlog.relatedPosts" :key="post.path">
                                    <a :href="useBlogUrl(post.path)" class="block py-2" @click="logBlogPostClick(path)">
                                        {{ post.title }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script setup>
import featuredPosts from "./featured-posts.json";
const { gtag } = useGtag();
const { locale } = useI18n();

const localeFeaturedBlog = featuredPosts.find((x) => x.languageCode == locale.value);

const postImage = computed(() => {
    return useBlogUrl(localeFeaturedBlog.image);
});

const logBlogPostClick = (path) => {
    const title =
        localeFeaturedBlog.path === path
            ? localeFeaturedBlog.title
            : localeFeaturedBlog.relatedPosts.find((x) => x.path === path).title;
    gtag("event", "home_featured_blog_post", { post_name: title });
};
</script>
