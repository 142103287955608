<template>
    <section class="shadow-under">
        <h2 class="text-lg mt-0 mb-4 font-bold uppercase text-center">{{ $t("Sponsors.Header") }}</h2>
        <div class="flex flex-wrap items-center justify-center sponsors-container">
            <template v-for="(sponsor, index) in sponsors" :key="sponsor.Id">
                <a :href="sponsor.Url" class="h-10 m-3">
                    <img
                        class="max-h-10 max-w-[140px] align-middle w-full h-auto"
                        :src="cdnUrl + sponsor.LogoUrl"
                        :alt="sponsor.Name" />
                </a>
                <div v-if="Math.floor(sponsors.length / 2) === index" class="lg:basis-full"></div>
            </template>
        </div>
        <div class="text-lg text-center mt-4 mb-8">
            <NuxtLink id="link-all-distributors" :external="true" :to="localePath('/distributors')">{{
                $t("Distributor.ViewAllParticipatingDistributors")
            }}</NuxtLink>
        </div>
    </section>
</template>
<script setup>
const localePath = useLangPath();
const { data: sponsors } = await useFetchLocaleApi("api/home/sponsors");
const config = useRuntimeConfig();
const cdnUrl = config.public.cdnUrl;
</script>
