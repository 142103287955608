<template>
    <section>
        <div class="flex mb-4 gap-4 items-baseline">
            <h2>
                {{ $t("Home.RootPartCategoriesHeader") }}
                <span class="text-base ml-2">
                    <NuxtLink :to="localePath('/all-categories')">{{ $t("PartCategory.ViewAllCategories") }}</NuxtLink>
                </span>
            </h2>
        </div>
        <div class="overflow-hidden">
            <div class="-mr-px -mb-px text-center grid grid-cols-[repeat(auto-fit,_minmax(11rem,_1fr))]">
                <NuxtLink
                    v-for="cat in categories"
                    :key="cat.Id"
                    :external="true"
                    :to="localePath('/part-category/' + cat.Path)"
                    class="px-2 py-6 h-40 border-b border-r flex flex-col">
                    <div class="flex-1">
                        <CategoryLogo :category="cat" class="mx-auto" size="lg" />
                    </div>
                    <div>{{ cat.Name }}</div>
                </NuxtLink>
            </div>
        </div>
    </section>
</template>
<script setup>
const localePath = useLangPath();

const { data: categories } = await useFetchLocaleApi("api/categories/top");
</script>
